import React from 'react';
import LineIcon from "react-lineicons";
import { useTranslation } from 'react-i18next';

function Service(props){
  const { t } = useTranslation();

  return (
    <div className="mi-service">
      <span className="mi-service-icon">
        <LineIcon name={props.content.icon} />
      </span>
      <h5>{t('service_title'+props.content.id)}</h5>
      <p>{t('service_details'+props.content.id)}</p>
    </div>
  )
}

export default Service;
