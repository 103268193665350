import React from "react";
import BackgroundLines from "../components/BackgroundLines";
import Header from "../components/Header";
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';

function Layout(props) {
  return (
    <I18nextProvider i18n={i18n}>
    <div className="mi-wrapper">
      <BackgroundLines />
      <Header />
      {props.children}
    </div>
    </I18nextProvider>
  );
}

export default Layout;
