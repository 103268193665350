import React from "react";
import { useTranslation } from 'react-i18next';

function Resume(props) {
  const { t } = useTranslation();
  const {year, position, graduation, university, company, details, languages, projects} = props.resumeData;
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{t('year'+props.resumeData.id,{year:year})}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position && t('position'+props.resumeData.id,{position:position}) || graduation && t('graduation'+props.resumeData.id,{graduation:graduation})}</h5>
        <h6 className="mi-resume-company">{company|| university}</h6>
        {t(`details${props.resumeData.id}`, { details: details }).split('\n').map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        {/*<p>{t('details'+props.resumeData.id,{details:details})}</p>*/}
        <p>{languages ? languages.map((lang) => <span className="mi-resume-lang"> {lang} </span>) : null }</p>
        <p>{projects ? projects.map((project) => <span className="mi-resume-project"> <a href={project} target="_blank">{project}</a> </span>) : null }</p>
      </div>
    </div>
  );
};

export default Resume;
