import Mock from "../mock";

const database = {
  information: {
    name: 'Julien Laglasse',
    aboutContent: "Développeur, IT consultant, chef de projet. Je manage et crée des sites et applications web dynamiques et interactifs. Je recherche actuellement un poste de Manager d'équipe de Développement IT",
    age: 33,
    phone: '',
    nationality: 'Français',
    language: 'Français, Anglais',
    email: '',
    address: 'Tokyo, Japan',
    freelanceStatus: 'Disponible',
    socialLinks: {
      facebook: '',
      twitter: 'https://twitter.com/yuniel777',
      pinterest: '',
      behance: '',
      linkedin: '',
      dribbble: '',
      github: 'https://github.com/julienlaglasse'
    },
    brandImage: '/images/cv.png',
    aboutImage: '/images/ju-about-image.jpg',
    aboutImageLg: '/images/ju-about-image-lg.jpg',
    cvfile: '/files/cv.pdf'
  },
  services: [
    {
      id:1,
      title: "Web Design",
      icon: 'pallet',
      details: "Proposer la meilleure expérience UX/UI possible pour les futurs utilisateurs afin que le produit soit le plus simple à utiliser. #optimisation"
    },
    {
      id:2,
      title: "Web Development",
      icon: 'code',
      details: "Bâtir des outils sur mesure, utilisant les dernières technologies évolutives pour répondre aux exigences de productivité et de performances. #customisation"
    },
    {
      id:3,
      title: "Stratégie Digitale",
      icon: 'mobile',
      details: "Du concept à sa diffusion, je m'appuie sur mes connaissances techniques et ma compréhension des enjeux liés à votre communication. #reflexion"
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Julien a fait preuve d'un grand professionnalisme dans la création et l'accompagnement de mon site web. "+
                "Il est à l'écoute et extrêmement réactif, en plus de fournir un produit fini au design dynamique. "+
                "Nous sommes en train de travailler sur ma boutique et je ne regrette pas d'avoir fait appel à lui. Je le recommande les yeux fermés!",
      author: {
        name: 'Vincent D.',
        designation: 'Breizh Micro Services, CEO',
        url: 'https://www.bms-ploermel.com'
      }
    },
    {
      id: 2,
      content: "Concernant la création de notre site yapiko.fr, nous en sommes très satisfaits. "+
                "Le cahier des charges a complètement été respecté. "+
                "Concernant le service relationnel/suivi client, il est très rapide et efficace !! "+
                "De même pour l'hébergement de notre site, en cas de problème, tout est réglé rapidement et correctement. "+
                "Excellent travail et très professionnel !!",
      author: {
        name: 'Séverine V.',
        designation: 'Yapiko Animation, CEO',
        url: 'http://yapiko.fr'
      }
    },
    {
      id: 3,
      content: "Efficace, disponible, rapide, collaborateur essentiel au lancement de Bonjour Tokyo, je ne peux que recommander."+
                "Très à l'aise avec les technologies, il fournit de très bons conseils et des solutions évolutives.",
      author: {
        name: 'Julien T.',
        designation: 'Bonjour Tokyo, CEO',
        url: 'http://bonjourtokyo.com'
      }
    },
    {
      id: 4,
      content: "Julien helped me when I had founded Find Your Element Workshop 5 years ago. He went way beyond what I had asked for. "+
              "As a tech dinosaur, it was so wonderful to have Julian who was always patient and full of helpful advice!",
      author: {
        name: 'Aya M.',
        designation: 'Aya Jean Style Coach, CEO',
        url: 'http://findyourelement.jp'
      }
    }
  ],
  skills: [
    {
      title: "HTML5",
      value: 100
    },
    {
      title: "CSS3",
      value: 100
    },
    {
      title: "Javascript",
      value: 100
    },
    {
      title: "jQuery",
      value: 100
    },
    {
      title: "ReactJS",
      value: 90
    },
    {
      title: "Photoshop",
      value: 70
    },
    {
      title: "Django",
      value: 70
    },
    {
      title: "Python",
      value: 70
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Gunkanjima Truth",
      subtitle: "Commande spéciale pour le Gouvernement Japonais",
      imageUrl: "/images/portfolio/gunkanjima.jpg",
      largeImageUrl: ["/images/portfolio/gunkanjima-lg.jpg"],
      url: 'https://www.gunkanjima-truth.com/'
    },
    {
      id: 2,
      title: "Shells",
      subtitle: "Environnement virtuel sur le cloud",
      imageUrl: "/images/portfolio/shells.jpg",
      largeImageUrl: [
        "/images/portfolio/shells-lg.jpg"
      ],
      url: 'https://www.shells.com/'
    },
    {
      id: 3,
      title: "InspireLive",
      subtitle: "Réservation dans l'évenementiel",
      imageUrl: "/images/portfolio/inspire.jpg",
      largeImageUrl: [
        "/images/portfolio/inspire-lg.jpg",
        "/images/portfolio/inspire-lg2.jpg"
      ],
      url: 'https://www.inspirelive.jp/'
    },
    {
      id: 4,
      title: "Otto Models",
      subtitle: "Vente de miniatures automobiles",
      imageUrl: "/images/portfolio/otto.jpg",
      largeImageUrl: ["/images/portfolio/otto-lg.jpg"],
      url: 'https://otto-models.com/'
    },
    {
      id: 5,
      title: "Bonjour Tokyo Bar",
      subtitle: "Design calendrier mensuel",
      imageUrl: "/images/portfolio/btbdesign.jpg",
      largeImageUrl: ["/images/portfolio/btbdesign-lg.jpg"]
    },
    {
      id: 6,
      title: "FutureFC MMA",
      subtitle: "Reservation Event MMA au brésil",
      imageUrl: "/images/portfolio/futurefcmma.jpg",
      largeImageUrl: ["/images/portfolio/futurefcmma-lg.jpg"],
      url: 'https://www.futurefcmma.com/'
    },
    {
      id: 7,
      title: "AtOnline",
      subtitle: "Site vitrine, présentation de différents services Web",
      imageUrl: "/images/portfolio/atonline.jpg",
      largeImageUrl: ["/images/portfolio/atonline-lg.jpg"],
      url: 'https://atonline.com'
    },
    {
      id: 8,
      title: "Breizh Micro Services",
      subtitle: "Site vitrine, vente hardware/software",
      imageUrl: "/images/portfolio/bms.jpg",
      largeImageUrl: ["/images/portfolio/bms-lg.jpg"],
      url: 'https://www.bms-ploermel.com/'
    },
    {
      id: 9,
      title: "GTS-models",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio/gts.jpg",
      largeImageUrl: ["/images/portfolio/gts-lg.jpg"],
      url: 'https://www.gts-models.com/'
    },
    {
      id: 10,
      title: "Numenor",
      subtitle: "Agence web",
      imageUrl: "/images/portfolio/numenor.jpg",
      largeImageUrl: ["/images/portfolio/numenor-lg.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "Find Your Element",
      subtitle: "Workshop",
      imageUrl: "/images/portfolio/fye.jpg",
      largeImageUrl: [
        "/images/portfolio/fye-lg.jpg"
      ],
      url: 'http://findyourelement.jp/'
    },
    {
      id: 12,
      title: "BTC.jp",
      subtitle: "Comparaison de cryptomonnaires - Data arbitrage",
      imageUrl: "/images/portfolio/btc.jpg",
      largeImageUrl: [
        "/images/portfolio/btc-lg.jpg"
      ],
      url: 'http://btc.jp/'
    },
    {
      id: 13,
      title: "AtOnline CMS",
      subtitle: "Création et management de CMS",
      imageUrl: "/images/portfolio/cms.jpg",
      largeImageUrl: ["/images/portfolio/cms-lg.jpg"],
      url: 'https://cms.atonline.com'
    },
    {
      id: 14,
      title: "AtOnline Naka",
      subtitle: "Gestion de support technique & commercial",
      imageUrl: "/images/portfolio/naka.jpg",
      largeImageUrl: ["/images/portfolio/naka-lg.jpg"],
      url: 'https://naka.atonline.com'
    },
    {
      id: 15,
      title: "Gamersunlock",
      subtitle: "Boutique en ligne de jeux vidéos",
      imageUrl: "/images/portfolio/gamersunlock.jpg",
      largeImageUrl: ["/images/portfolio/gamersunlock-lg.jpg"],
      url: 'https://www.gamersunlock.com/'
    },
    {
      id: 16,
      title: "Filesend",
      subtitle: "Partage de fichiers en ligne",
      imageUrl: "/images/portfolio/filesend.jpg",
      largeImageUrl: ["/images/portfolio/filesend-lg.jpg"],
      url: 'https://filesend.jp'
    },
    {
      id: 17,
      title: "SCAI",
      subtitle: "Suite d'outils incluant chat, camera de sécurité, appels vocaux",
      imageUrl: "/images/portfolio/scai.jpg",
      largeImageUrl: ["/images/portfolio/scai-lg.jpg","/images/portfolio/scai2-lg.jpg","/images/portfolio/scai3-lg.jpg"]
    },
    {
      id: 18,
      title: "Nappli",
      subtitle: "Gestion de cartes de visites.",
      imageUrl: "/images/portfolio/nappli.jpg",
      largeImageUrl: ["/images/portfolio/nappli-lg.jpg"],
      url: 'https://www.nappli.com/'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 8,
        year: "",
        position: "",
        company: "Guydeez",
        details: "",
        languages:["react","node","express","typescript","nextjs","socket","aws","s3","ec2","mui","fuse"],
        projects: [""]
      },
      {
        id: 7,
        year: "",
        position: "",
        company: "Hazama Ando",
        details: "",
        languages:["python","django","jquery","reactjs","node","socket","aws","s3","ec2","kinesis","lambda", "mui", "apexcharts"],
        projects: [""]
      },
      {
        id: 6,
        year: "",
        position: "",
        company: "Karpelès Lab",
        details: "",
        languages: ["React/redux","HTML5","SASS","material-Ui / react-ui framework"],
        projects: ["https://shells.com","https://inspirelive.jp","https://www.filesend.jp"]
      },
      {
        id: 5,
        year: "",
        position: "",
        company: "London Trust Media",
        details: "",
        languages: ["JS/jQuery","React","HTML5","SASS","material-Ui / react-ui framework"],
        projects: ["https://bitcoinvpn.com","https://futurefcmma.com"]
      },
      {
        id: 4,
        year: "",
        position: "",
        company: "Tristan Technologies",
        details: "",
        languages: ["JS/jQuery","React","React native","HTML5","SASS","material-Ui / react-ui framework"],
        projects: ["https://gunkanjima-truth.com","http://echelle.co.jp/"]
      },
      {
        id: 3,
        year: "",
        position: "",
        company: "Shade3D",
        details: "",
        languages: ["JS/jQuery", "e-commerce", "three.js"],
        projects: ["https://shop.shade3d.co.jp/","https://shade3d.jp/"]
      },
      {
        id: 2,
        year: "",
        position: "",
        company: "Tibanne",
        details: "",
        languages: ["JS/jQuery", "html", "css"],
        projects: ["https://atonline.com/"]
      },
      {
        id: 1,
        year: "",
        position: "",
        company: "Mini Express",
        details: "",
        languages: ["JS/jQuery", "html", "css"],
        projects: ["https://otto-models.com/"]
      },
      {
        id: 0,
        year: "",
        position: "",
        company: "Roll&Play / Numenor Group",
        details: "",
        languages: ["React/redux", "JS/jQuery", "mysql/postgres", "AWS", "Photoshop/Figma/Adobe XD", '...'],
        projects: ["https://numenor.fr/"]
      }
    ],
    educationExperience: [
      {
        id: 10,
        year: "2007 - 2008",
        graduation: "DEES Informatique",
        university: "ESUP - Rennes",
        details: ""
      },
      {
        id: 9,
        year: "2005 - 2007",
        graduation: "BTS Informatique de Gestion",
        university: "ESUP - Rennes",
        details: ""
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['070-9144-7771'],
    emailAddress: ['julienlaglasse@gmail.com'],
    address: "Tokyo, Japan"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});